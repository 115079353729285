<template>
  <div>
    <div>
      <b-table
        :items="items"
        :fields="$t('clan')=='en'?fields:fieldsAR"
        :responsive="true"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        show-empty
      >
        <template #cell(actions)="row">
          <div class="">
            <button @click.prevent="viewQueue(row.item.id)">
              <i class="far fas fa-eye"></i>
              {{$t('view')}}
            </button>
            <button
              @click.prevent="showModal(row.item.id, row.item.name)"
              class="ml-5"
            >
              <i class="fas fa-trash mr-2"></i>{{$t('Delete')}}
            </button>
          </div>
        </template>


       

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

     <!-- <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="left"
        size="lg"
        class="my-0"
        @click.native="getData"
      ></b-pagination> -->

      <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr">
    <li class="page-item "><a class="page-link" >{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link" >{{$t('Next')}}</a></li>
  </ul>
    </div>

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      :title=" 'Tarrif Deletion'"
      hide-footer
    >
      <p>
        Are you sure that you want to delete this?
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="deleteQeue">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
//import { mapGetters } from "vuex";

export default {
  name: "QueuesTable",
  async mounted() {
    await this.$store.dispatch("queues/loadAll").then((res) => {
      this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
    });
  },

  computed: {
    //...mapGetters({ table: "queues/table" }),
    //...mapGetters({ tableAR: "queues/tableAR" }),
  },
  data: function () {
    return {
      queue: {
        id: null,
      },
      tempId: null,
      tempName: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      firstPage:1,
      lastPage:'',
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "tarrifname",
          label: "Tarrif Name",
          sortable: true,
          //class: "text-center",
        },
        {
          key: "tarrifvalue",
          label: "Tarrif Value",
          sortable: true,
          //class: "text-center",
        },
        {
          key: "tarrifmode",
          label: "Tarrif Mode",
          sortable: true,
          //class: "text-center",
        },
        { key: "actions", label: "Actions" },

        
      ],

      fieldsAR: [
        {
          key: "id",
          label: "المعرف",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "tarrifname",
          label: "اسم التعرفة",
          sortable: true,
          class: "text-center",
        },
        {
          key: "tarrifvalue",
          label: "قيمة التعرفة",
          sortable: true,
          class: "text-center",
        },
        {
          key: "tarrifmode",
          label: "وضع التعرفة",
          sortable: true,
          //class: "text-center",
        },
        
        { key: "actions", label: "إعدادات" },
        ],

        items:[
        {
            id:'1',
            tarrifname:'as',
            tarrifmode:'Percentage',
            tarrifvalue:'2.3'
            
        },
        {
            id:'2',
            tarrifname:'ttt',
            tarrifmode:'Fixed',
            tarrifvalue:'2.7'
            
            
        },
        {
            id:'3',
            tarrifname:'qer',
            tarrifmode:'Fixed',
            tarrifvalue:'3.3'
            
        },
    ]
    };
  },
  methods: {
    /*next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      
      this.getData()

    },*/
    /*prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
      
      this.getData()

    },
    async getData() {
      await this.$store.dispatch("queues/loadPage", this.currentPage);
    },*/
    viewQueue(id) {
      this.$router.push({ name: "EditTarrif", params: { id: id } });
    },
    async deleteQeue() {
      this.queue.id = this.tempId;
      await this.$store.dispatch("queues/delete", this.queue);
      await this.$store.dispatch("queues/loadAll").then((res) => {
        this.totalRows = res.data.total;
      });
      this.hideModal();
    },

    showModal(id, name) {
        
      this.$refs["my-modal"].show();
      this.tempId = id;
      this.tempName = name;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>
<style lang="css" scoped>
</style>